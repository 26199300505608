import React, { useEffect, useState } from "react";
import {
  Card,
  Carousel,
  Col,
  Form,
  Input,
  Layout,
  Row,
  theme,
  Tooltip,
  Button,
  Typography,
  Divider,
  Space,
} from "antd";
import Logo from "../images/logo.jpeg";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import {
  getCountriesLabel,
  getDateFormatted,
  getErrorImgForProfile,
  getLabelfromLookup,
  getLookupfromLocal,
  getProfilePicAsURL,
  getStateAndCityLabels,
} from "../utils/utils";
import { FaUserGroup } from "react-icons/fa6";

import Icon from "@ant-design/icons/lib/components/Icon";

import {
  RightCircleOutlined,
  HeartOutlined,
  EyeOutlined,
  EditOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  getlookupdata,
  getLookupDataFromLocalStorage,
  getPersonalDetails,
  getProfilePic,
  getUserDetails,
  getUserDetailsFromLocalStorage,
  setUserDetailsInLocalStorage,
} from "../helperFunctions/endpoints";
import { findLabelByValueAndKey } from "../utils/utils";
import NavBar from "../genericComponents/CustomNav";
import ImageUploadModal from "../genericComponents/ImageUploadModal";
import { uploadFiles } from "../helperFunctions/utils";
import { toast } from "react-toastify";
const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const createImageUrl = (userId, fileName) => {
  return `${process.env.REACT_APP_API_URL}profile/file/${userId}/${fileName}`;
};

export const MyProfile = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getUserForUI();
    window.scrollTo(0, 0);
    getPersonalData();
  }, []);

  const [lookupData, setLookupData] = useState(getLookupDataFromLocalStorage());
  const [personaldata, setPersonalData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [cityLabels, setCityLabels] = useState();
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const updateProfilePic = async (file) => {
    const formData = new FormData();
    formData.append("profilePic", file);
  };

  const getUserForUI = () => {
    getUserDetails().then((res) => {
      if (res?.code === "CH200") {
        setUserDetailsInLocalStorage(res?.data);
        setUserDetails(res?.data);
        setImageUrl(getProfilePicAsURL(res?.data?._id, res?.data?.profilePic));
      }
    });
  };

  const getPersonalData = () => {
    getPersonalDetails().then(async (res) => {
      console.log(res);
      if (res?.code === "CH200") {
        setPersonalData(res?.data);
        const labels = await getStateAndCityLabels(
          res?.data?.miscellaneous?.country,
          res?.data?.miscellaneous?.state,
          res?.data?.miscellaneous?.city
        );
        setCityLabels(labels);
      }
    });
  };

  const uploadPicToUrl = async (fileObj) => {
    setLoading(true);
    try {
      await uploadFiles("profile/uploadProfilePic", {
        profilePhoto: fileObj,
        primary: true,
      });
      getUserForUI();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    toast.success("Profile picture updated successfully");
    setUserDetails(userDetails);
    setShowImageUploadModal(false);
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />

      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "strat",
              flexDirection: "column",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <img
                src={imageUrl}
                onError={(e) => {
                  e.target.src = getErrorImgForProfile(userDetails);
                }}
                alt=""
                height={120}
                width={120}
                style={{
                  borderRadius: 50,
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </div>
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={() => setShowImageUploadModal(true)}
            >
              Edit
            </Button>
            <Button type="primary" onClick={() => navigate("/addprofilepic")}>
              + Add More Photos
            </Button>
            <div className="myprofile-view">
              <div style={{ padding: "8px" }}>
                <Row>
                  <Col span={22}>{"HEARTS-" + userDetails?.heartsId}</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      icon={<FormOutlined style={{ color: "red" }} />}
                      onClick={() => navigate("/editprofile/basic")}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Name
                  </Col>
                  <Col span={12}>
                    {userDetails?.name || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Gender
                  </Col>
                  <Col span={12}>
                    {userDetails?.gender == "F"
                      ? "Female"
                      : "Male" || (
                          <Text style={{ color: "red" }}>Not Filled</Text>
                        )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Religion
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.basic?.religion,
                      "religion"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Mother Tongue
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.basic?.motherTongue,
                      "motherTongue"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Country
                  </Col>
                  <Col span={12}>
                    {cityLabels?.country || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    State
                  </Col>
                  <Col span={12}>
                    {cityLabels?.state || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    City
                  </Col>
                  <Col span={12}>
                    {cityLabels?.city || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Income{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.basic?.income,
                      "income"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Caste{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.basic?.cast,
                      "casts"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Height
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.basic?.height,
                      "height"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  borderColor: "#f1c40f",
                  backgroundColor: "#f1c40f2e",
                  borderRadius: "10px",
                  padding: "1rem",
                }}
              >
                <Row className="col-title-color">Critical Field</Row>
                <Row>
                  {getDateFormatted(personaldata?.critical?.dob) || (
                    <Text style={{ color: "red" }}>Not Filled</Text>
                  )}
                </Row>
                <Row>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.critical?.maritalStatus,
                      "maritalStatus"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "8px" }}>
                <Row className="col-title-color">
                  <Col span={22}>About Me</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      icon={
                        <FormOutlined
                          style={{
                            color: "red",
                          }}
                        />
                      }
                      onClick={() => navigate("/editprofile/about")}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Tell us About YourSelf
                  </Col>
                  <Col span={12}>
                    {personaldata?.about?.description || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Profile Managed By
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.about?.managedBy,
                      "managedBy"
                    ) || <Text style={{ color: "red" }}> Not Filled </Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Disability{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.about?.disability,
                      "disability"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Body Type
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.about?.bodyType,
                      "bodyType"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Thalassemia{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.about?.thalassemia,
                      "thalassemia"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    HIV Positive{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.about?.hivPositive,
                      "single_decision"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "8px" }}>
                <Row className="col-title-color">
                  {" "}
                  <Col span={22}>Education</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      onClick={() => navigate("/editprofile/education")}
                      icon={<FormOutlined style={{ color: "red" }} />}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    About My Education
                  </Col>
                  <Col span={12}>
                    {personaldata?.education?.aboutEducation || (
                        <Text style={{ color: "red" }}>Not Filled</Text>
                      ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Qualification{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.education?.qualification,
                      "highestEducation"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Other UG Degree
                  </Col>
                  <Col span={12}>
                    {personaldata?.education?.otherUGDegree || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    School{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.education?.school || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "8px" }}>
                <Row className="col-title-color">
                  {" "}
                  <Col span={22}>Career</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      icon={<FormOutlined style={{ color: "red" }} />}
                      onClick={() => navigate("/editprofile/career")}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    About My Career
                  </Col>
                  <Col span={12}>
                    {personaldata?.career?.aboutMyCareer || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Employed In{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.career?.employed_in,
                      "employed_in"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Occupation
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.career?.occupation,
                      "occupation"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Organisation Name{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.career?.organisationName || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Intrested In Setting Abroad{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.career?.interestedInSettlingAbroad,
                      "single_decision"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "8px" }}>
                <Row className="col-title-color">
                  <Col span={22}> Family</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      icon={<FormOutlined style={{ color: "red" }} />}
                      onClick={() => navigate("/editprofile/family")}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    About My Family
                  </Col>
                  <Col span={12}>
                    {personaldata?.family?.aboutMyFamily || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Family Status{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.family?.familyStatus,
                      "familyStatus"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Family Type
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.family?.familyType,
                      "familyType"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Family values{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.family?.familyValues,
                      "familyValues"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Family Income{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.family?.familyIncome,
                      "income"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Father Is{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.family?.fatherOccupation,
                      "fathersOccupation"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Mother Is{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.family?.motherOccupation,
                      "mothersOccupation"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    {" "}
                    Brothers{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.family?.brothers || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Married Brothers{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.family?.marriedBrothers || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Sisters{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.family?.sisters || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Married Sisters{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.family?.marriedSisters || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Gothra{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.family?.gothra || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Living With Parents{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.family?.livingWithParents,
                      "livingWithParents"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Family Based{" "}
                  </Col>
                  <Col span={12}>
                    {cityLabels?.country || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "8px" }}>
                <Row className="col-title-color">
                  {" "}
                  <Col span={22}>Contact Details</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      onClick={() => navigate("/editprofile/contact")}
                      icon={<FormOutlined style={{ color: "red" }} />}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Mobile Number
                  </Col>
                  <Col span={12}>
                    {personaldata?.contact?.phoneNumber || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Email Id{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.contact?.email || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Alternate Mobile No
                  </Col>
                  <Col span={12}>
                    {personaldata?.contact?.altMobileNumber || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Alternate Email Id{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.contact?.alternateEmail || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Landline{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.contact?.landline || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "8px" }}>
                <Row className="col-title-color">
                  {" "}
                  <Col span={22}>Horoscope</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      onClick={() => navigate("/editprofile/horoscope")}
                      icon={<FormOutlined style={{ color: "red" }} />}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Rashi
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.horoscope?.rashi,
                      "rashi"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    {" "}
                    Nakshatra{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.horoscope?.nakshatra,
                      "nakshatra"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Place Of Birth
                  </Col>
                  <Col span={12}>
                    {cityLabels?.city || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Time of Birth{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.horoscope?.timeOfBirth || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Manglik{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.horoscope?.manglik,
                      "manglik"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Horoscope{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.horoscope?.horoscope,
                      "horoscopes"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "8px" }}>
                <Row className="col-title-color">
                  <Col span={22}>Life Style</Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      icon={<FormOutlined style={{ color: "red" }} />}
                      onClick={() => navigate("/editprofile/lifestyle")}
                      style={{ display: "inline" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <b>Habits</b>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Dietary Habits
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.lifeStyleData?.dietaryHabits,
                      "dietaryHabits"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Drinking Habits{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.lifeStyleData?.drinkingHabits,
                      "smokingHabits"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Smoking Habits
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.lifeStyleData?.smokingHabits,
                      "smokingHabits"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <b>Assets</b>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Own a House ?{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.lifeStyleData?.ownAHouse,
                      "smokingHabits"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Own a Car ?{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.lifeStyleData?.ownACar,
                      "smokingHabits"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Open to Pets ?{" "}
                  </Col>
                  <Col span={12}>
                    {findLabelByValueAndKey(
                      personaldata?.lifeStyleData?.openToPets,
                      "smokingHabits"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Languages I Known
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.languages,
                      lookupData,
                      "motherTongue"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Hobbies{" "}
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.hobbies,
                      lookupData,
                      "hobbies"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Interests
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.interest,
                      lookupData,
                      "interests"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Food i Cook{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.lifeStyleData?.foodICook || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Favourite Music
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.favMusic,
                      lookupData,
                      "music"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Favourite Read{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.lifeStyleData?.favRead || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Dress Style{" "}
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.dress,
                      lookupData,
                      "dressStyle"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Sports{" "}
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.sports,
                      lookupData,
                      "sports"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Books{" "}
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.books,
                      lookupData,
                      "books"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Favourite Cuisine{" "}
                  </Col>
                  <Col span={12}>
                    {getLabelfromLookup(
                      personaldata?.lifeStyleData?.cuisine,
                      lookupData,
                      "cuisines"
                    ) || <Text style={{ color: "red" }}>Not Filled</Text>}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Favourite Movies{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.lifeStyleData?.movies || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Favourite Tv Shows{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.lifeStyleData?.favTVShow || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ color: "#8c8c8c" }}>
                    Vacation Destination{" "}
                  </Col>
                  <Col span={12}>
                    {personaldata?.lifeStyleData?.vacayDestination || (
                      <Text style={{ color: "red" }}>Not Filled</Text>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Content>
        <ImageUploadModal
          isModalOpen={showImageUploadModal}
          loading={verifyLoading}
          subTitle={"Image Upload"}
          uploadClickButton={uploadPicToUrl}
          onCancelClick={() => setShowImageUploadModal(false)}
        />
      </Layout>
    </Layout>
  );
};

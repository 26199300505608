import React, { useState } from "react";
import { Form, Input, Button, Typography, notification, Col, Row } from "antd";
import {
  CaretLeftOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Logo from "../images/logo.jpeg";
import MobileNumberInput from "../genericComponents/MobileNumberInput";
import { countryCodesWithIcons } from "../constants/countrycodes";
import {
  getforgotpassword,
  postforgototpsubmit,
} from "../helperFunctions/endpoints";
import { useNavigate } from "react-router-dom";
import OtpModal from "../genericComponents/OtpModal";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const handleForgotPassword = async (values) => {
    try {
      const { countryCode, phoneNumber } = values;
      setPhoneNumber(`${phoneNumber}`);
      const response = await getforgotpassword(phoneNumber);
      console.log(response);
      setShowOtpModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
  };

  const verifyOTP = async () => {
    setVerifyLoading(true);
    try {
      const response = await postforgototpsubmit({
        phoneNumber,
        otp,
      });
      if (response?.code === "CH400") {
        toast.error(response?.err);
      }
      setShowOtpModal(false);
    } catch (error) {
      console.log(error);
    }
    setVerifyLoading(false);
  };

  return (
    <Row type="flex" align="middle">
      <Col xs={24} sm={24} md={24} lg={14} xl={14}>
        <div
          className="login-image"
          style={{
            display: "block",
            backgroundImage:
              "url(https://d3nn873nee648n.cloudfront.net/1200x1800-new/20354/SM1015626.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <div className="login-margin">
          <Title level={3} className="title">
            <img
              key="rootloader"
              src={Logo}
              alt="no_image"
              className="login-logo"
            />
            Connecting Hearts
          </Title>
          <div>
            <Title level={4} className="title">
              Forgot Password
            </Title>
          </div>
          <Form
            layout="vertical"
            name="loginForm"
            form={form}
            onFinish={handleForgotPassword}
            initialValues={{
              countryCode: "+91",
            }}
          >
            <Form.Item
              label={"Mobile Number"}
              name={"phoneNumber"}
              type="number"
              rules={[
                {
                  required: true,
                  message: "Please input your Mobile Number!",
                },
              ]}
            >
              <MobileNumberInput
                name={"phoneNumber"}
                countryCodeOptions={countryCodesWithIcons?.map((i) => {
                  return {
                    label: `${i.emoji} ${i.value}`,
                    value: i.value,
                  };
                })}
                onChangeHandler={handleFormChanges}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="border-Radius-5px block-button-text height-100p"
              >
                Send OTP
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="text"
            block
            className="border-Radius-5px block-button-text height-100p back-button"
            onClick={() => navigate("/")}
          >
            <CaretLeftOutlined />
            Back
          </Button>
        </div>
      </Col>
      <OtpModal
        otp={otp}
        setOtp={setOtp}
        isModalOpen={showOtpModal}
        confirmOTP={verifyOTP}
        loading={verifyLoading}
        subTitle={"Check your Phone and Fill the OTP"}
      />
    </Row>
  );
};

export default ForgotPassword;

export const socialDetailsFormFields = [
  {
    name: "maritalStatus",
    label: "Marital Status",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    rules: [
      {
        required: true,
        message: "Please select your marital status!",
      },
    ],
    lookups: "maritalStatus",
  },
  {
    name: "haveChildren",
    label: "Have Children ?",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "haveChildren",
  },
  {
    name: "motherTongue",
    label: "Mother Tongue",
    placeHolder: "Select Mother Tongue",
    rules: [
      {
        required: true,
        message: "Please input your mother tongue!",
      },
    ],
    fieldType: "singleSelect",
    lookups: "motherTongue",
  },
  {
    name: "religion",
    label: "Religion",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "religion",
  },
  {
    name: "castNoBar",
    label: "Caste No Bar",
    placeHolder: "I am Open to marry people of any caste",
    fieldType: "switch",
  },
  {
    name: "caste",
    label: "Caste",
    placeHolder: "Select Caste",
    fieldType: "singleSelect",
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const castNoBar = getFieldValue("castNoBar");
          if (castNoBar || value) {
            return Promise.resolve();
          }
          if (!value) {
            return Promise.reject(new Error("Please select Caste!"));
          }
        },
      }),
    ],
    lookups: "casts",
    nestedLookups: "religion",
  },
  {
    name: "horoscope",
    label: "Horoscope",
    placeHolder: "Select Horoscope",
    rules: [
      {
        required: true,
        message: "Please input your horoscope!",
      },
    ],
    fieldType: "singleSelect",
    lookups: "horoscopes",
  },
  {
    name: "manglik",
    label: "Manglik",
    fieldType: "singleSelect",
    placeHolder: "Select",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your other degrees!",
    //   },
    // ],
    lookups: "manglik",
  },
];

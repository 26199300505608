import { Button, Col, Form, Layout, Typography, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import Progressbar from "../genericComponents/Progressbar";
import { useDispatch, useSelector } from "react-redux";
import { socialDetailsFormFields } from "../constants/socialDetailsFormFields";
import { useNavigate, useLocation } from "react-router-dom";
import { updatePersonalData } from "../store/slices/personalDetailSlice";
import AjaXpress from "../helperFunctions/api";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { CaretLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { CustomSider } from "../genericComponents/CustomSider";
import NavBar from "../genericComponents/CustomNav";
import { updateLastScreenActivity } from "../helperFunctions/endpoints";

const SocialDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(socialDetailsFormFields);
  const lookupData = useSelector((state) => state.lookup.lookupData);
  const personalDetails = useSelector((state) => state.personalDetails);
  const [loading, setLoading] = useState({ get: false, set: false });
  let { id } = useParams();
  const { Header, Content, Footer } = Layout;

  const getPersonalDetails = async () => {
    setLoading((loading) => {
      return { ...loading, get: true };
    });
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `personalDetails`,
      });
      if (response?.response?.status == 200) {
        let data = response.data.data;
        let obj = {
          gender: data?.gender,
          dob: data?.dob ? dayjs(data.dob) : null,
          height: data?.height,
          country: data?.country,
          state: data?.state,
          city: data?.city,
          residentialStatus: data?.residentialStatus,
          education: data?.education?.qualification,
          otherPgDegree: data?.education?.otherUGDegree,
          employedIn: data?.employed_in,
          occupation: data?.occupation,
          income: data?.income,
          maritalStatus: data?.maritalStatus,
          haveChildren: data?.haveChildren,
          motherTongue: data?.motherTongue,
          religion: data?.religion,
          caste: data?.cast,
          horoscope: data?.horoscope,
          manglik: data?.manglik,
        };
        Object.keys(obj)?.forEach((item) => {
          handleFormChanges(item, obj[item]);
        });
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, get: false };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPersonalDetails();
    updateLastScreenActivity("socialdetails");
  }, []);

  useEffect(() => {
    if (Object.keys(lookupData).length) {
      let data = [...socialDetailsFormFields].filter((i) => {
        if (
          i.name === "haveChildren" &&
          (personalDetails.maritalStatus === "nvm" ||
            !personalDetails.maritalStatus)
        )
          return false;
        return true;
      });
      data = data.map((field) => {
        return {
          ...field,
          defaultValueGetter: personalDetails,
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
        };
      });
      if (personalDetails?.religion) {
        data = data.map((field) => {
          if (field.name !== "caste") return field;
          return {
            ...field,
            ...(field?.lookups?.length && {
              options: lookupData[field?.lookups]?.filter(
                (i) => i.religion === personalDetails?.religion
              ),
            }),
          };
        });
      }
      setFields(data);
    }
  }, [lookupData, personalDetails]);

  const addPersonalDetails = async () => {
    setLoading((loading) => {
      return { ...loading, set: true };
    });
    try {
      let response = await AjaXpress({
        method: "PATCH",
        body: {
          gender: personalDetails.gender,
          dob: personalDetails.dob,
          height: personalDetails.height,
          employed_in: personalDetails.employedIn,
          country: personalDetails.country,
          state: personalDetails.state,
          city: personalDetails.city,
          motherTongue: personalDetails.motherTongue,
          religion: personalDetails.religion,
          cast: personalDetails.castNoBar ? null : personalDetails.caste,
          horoscope: personalDetails.horoscope,
          manglik: personalDetails.manglik,
          income: personalDetails.income,
          residentialStatus: personalDetails.residentialStatus,
          maritalStatus: personalDetails.maritalStatus,
          occupation: personalDetails.occupation,
          haveChildren: personalDetails.haveChildren,
          castNoBar: personalDetails.castNoBar,
          education: {
            qualification: personalDetails.education,
            otherUGDegree: personalDetails.otherPgDegree,
          },
        },
        slug: "personalDetails",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);

        if (id) {
          navigate("/myprofile");
        } else {
          navigate("/srcmform");
        }
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, set: false };
    });
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
    dispatch(
      updatePersonalData({
        key: name,
        value: value,
      })
    );
  };
  const handleNext = (values) => {
    addPersonalDetails();
  };
  const { state } = useLocation();
  const { Text } = Typography;
  return (
    <Layout>
      {!!id && <NavBar navigate={navigate} />}
      <Layout>
        {!!id && <CustomSider navigate={navigate} />}
        <Content className="content-wrapper">
          <Row type="flex" align="middle" justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={16} xl={12}>
              <div className="login-margin" style={{ marginBottom: "3rem" }}>
                {!id && <Progressbar percent={60} />}
                <div className="sub-title">Fill In your Social Details</div>
                <div style={{ padding: "1rem" }}>
                  Provide additional information like your Marital Status,
                  Religion, Mother Tongue and more.
                </div>
                <Form
                  layout="vertical"
                  name="loginForm"
                  form={form}
                  onFinish={handleNext}
                  initialValues={{
                    maritalStatus: personalDetails?.maritalStatus,
                    haveChildren: personalDetails?.haveChildren,
                    motherTongue: personalDetails?.motherTongue,
                    religion: personalDetails?.religion,
                    caste: personalDetails?.caste,
                    castNoBar: personalDetails?.castNoBar,
                    horoscope: personalDetails?.horoscope,
                    manglik: personalDetails?.manglik,
                  }}
                >
                  {fields?.map((Field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Item
                          label={Field.label}
                          name={Field.name}
                          rules={Field.rules}
                          style={Field.style ? Field.style : {}}
                          {...(Field.feedBack && {
                            hasFeedback: Field.feedBack,
                          })}
                          {...(Field.valuePropName && {
                            valuePropName: Field.valuePropName,
                          })}
                          hidden={
                            Field.name === "caste" &&
                            form.getFieldValue("castNoBar")
                          }
                        >
                          {formFieldRenderer(Field, handleFormChanges)}
                        </Form.Item>
                      </React.Fragment>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="border-Radius-5px block-button-text height-100p"
                      loading={loading.set}
                    >
                      {id ? "Update" : "Next"}
                    </Button>
                  </Form.Item>
                </Form>
                {!id && (
                  <Button
                    type="text"
                    block
                    className="border-Radius-5px block-button-text height-100p back-button"
                    onClick={() => navigate("/careerdetails")}
                  >
                    <CaretLeftOutlined />
                    Back
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SocialDetails;

import { Button, Col, Form, Modal, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import Progressbar from "../genericComponents/Progressbar";
import { useDispatch, useSelector } from "react-redux";
import { srcmformFields } from "../constants/srcmFormFields";
import { useNavigate } from "react-router-dom";
import { updateSrcmlData } from "../store/slices/srcmSlice";
import { uploadFiles } from "../helperFunctions/utils";
import AjaXpress from "../helperFunctions/api";
import {
  CaretLeftOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { updateLastScreenActivity } from "../helperFunctions/endpoints";
import ImageUploadModal from "../genericComponents/ImageUploadModal";

const SrcmDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(srcmformFields);
  const [srcmFileName, setSrcmFileName] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const srcmData = useSelector((state) => state.srcmData);

  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileObj, setFileObj] = useState();
  const [loader, setLoader] = useState({ get: false, set: false });

  const handleChange = (info) => {
    setFileObj(info.file.originFileObj);
  };

  const handlePreview = async () => {
    setPreviewOpen(true);
  };

  const createImageUrl = (fileName) => {
    return `${process.env.REACT_APP_API_URL}srcmDetails/file/${fileName}`;
  };

  const getSrcmDetails = async () => {
    setLoader((loader) => {
      return { ...loader, get: true };
    });
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `srcmDetails`,
      });
      if (response?.response?.status == 200) {
        let data = response?.data?.data;
        let obj = {
          srcmIdNumber: data?.srcmIdNumber,
          satsungCenter: data?.satsangCentreName,
          preceptorName: data?.preceptorsName,
          preceptorPhoneNumber: data?.preceptorsContactNumber,
          preceptorEmail: data?.preceptorsEmail,
        };
        if (data?.srcmIdFilename?.length) {
          setImageUrl(createImageUrl(data?.srcmIdFilename));
          setSrcmFileName(data?.srcmIdFilename);
        }
        Object.keys(obj)?.forEach((item) => {
          handleFormChanges(item, obj[item]);
        });
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader((loader) => {
      return { ...loader, get: false };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSrcmDetails();
    updateLastScreenActivity("srcmform");
  }, []);

  const uploadButton = (
    // Upload Button
    <div
      style={{
        display: "flex",
        width: "300px",
        height: "200px",
        border: "2px dashed gray",
        borderRadius: "5px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        setShowImageUploadModal(true);
      }}
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  const handleFormChanges = async (name, value) => {
    form.setFieldValue(name, value);
    dispatch(
      updateSrcmlData({
        key: name,
        value: value,
      })
    );
  };

  const uploadPicToUrl = async (fileObj) => {
    setLoading(true);
    try {
      let res = await uploadFiles("srcmDetails/uploadSrcmId", {
        srcmPhoto: fileObj,
      });
      setImageUrl(createImageUrl(res?.data?.fileName));
      setSrcmFileName(res?.data?.fileName);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setShowImageUploadModal(false);
  };

  const addSrcmDetails = async () => {
    setLoader((loader) => {
      return { ...loader, set: true };
    });
    try {
      let response = await AjaXpress({
        method: "PATCH",
        body: {
          srcmIdNumber: srcmData.srcmIdNumber,
          satsangCentreName: srcmData.satsungCenter,
          preceptorsName: srcmData.preceptorName,
          preceptorsContactNumber: srcmData.preceptorPhoneNumber,
          preceptorsEmail: srcmData.preceptorEmail,
          srcmIdFilename: srcmFileName,
        },
        slug: "srcmDetails/updateSrcmDetails",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);
        navigate("/profilepic");
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader((loader) => {
      return { ...loader, set: true };
    });
  };
  const handleNext = (values) => {
    setOpenModal(true);
  };
  const confirmSRCMData = () => {
    addSrcmDetails();
  };
  return (
    <Row type="flex" align="middle" justify={"center"}>
      <Col xs={24} sm={24} md={24} lg={16} xl={12}>
        <div className="login-margin" style={{ marginBottom: "3rem" }}>
          <Progressbar percent={80} />
          <div className="sub-title">
            Fill In your SRCM/Heartfulness Details
          </div>
          <div style={{ padding: "1rem" }}>
            Provide additional information like your SRCM Details.
          </div>
          <div>SRCM ID</div>
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                maxHeight: "8rem",
              }}
            />
          ) : (
            uploadButton
          )}
          <Form
            layout="vertical"
            name="loginForm"
            form={form}
            onFinish={handleNext}
            initialValues={{
              srcmIdNumber: srcmData?.srcmIdNumber,
              satsungCenter: srcmData?.satsungCenter,
              preceptorName: srcmData?.preceptorName,
              preceptorPhoneNumber: srcmData?.preceptorPhoneNumber,
              preceptorEmail: srcmData?.preceptorEmail,
            }}
          >
            {fields?.map((Field, index) => {
              return (
                <React.Fragment key={index}>
                  <Form.Item
                    label={Field.label}
                    name={Field.name}
                    rules={Field.rules}
                    style={Field.style ? Field.style : {}}
                    {...(Field.feedBack && { hasFeedback: Field.feedBack })}
                    {...(Field.valuePropName && {
                      valuePropName: Field.valuePropName,
                    })}
                  >
                    {formFieldRenderer(Field, handleFormChanges)}
                  </Form.Item>
                </React.Fragment>
              );
            })}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="border-Radius-5px block-button-text height-100p"
              >
                Next
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="text"
            block
            className="border-Radius-5px block-button-text height-100p back-button"
            onClick={() => navigate("/socialdetails")}
          >
            <CaretLeftOutlined />
            Back
          </Button>
        </div>
        <Modal
          title="Confirmation"
          open={openModal}
          width={"fit-content"}
          className="border-Radius-5px"
          closeIcon={null}
          footer={null}
        >
          <div style={{ marginBottom: "0.5rem", fontSize: "1.2rem" }}>
            {"Data Submitted Cannot be Changed"}
          </div>
          <div>
            {
              "Please ensure all the data entered is correct before submission as it cannot be changed or edited again"
            }
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <Button
              type="text"
              onClick={() => setOpenModal(false)}
              className="block-button-text height-100p"
            >
              Back to Edit
            </Button>
            <Button
              type="primary"
              className="border-Radius-5px block-button-text height-100p"
              onClick={confirmSRCMData}
              loading={loader.set}
            >
              Confirm
            </Button>
          </div>
        </Modal>
        <Modal
          open={previewOpen}
          footer={null}
          onCancel={handleCancel}
          width={"fit-content"}
          styles={{ body: { margin: 0 } }}
          closeIcon={
            <div
              style={{
                background: "white",
                color: "red",
                borderRadius: "50%",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              X
            </div>
          }
        >
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        </Modal>
      </Col>
      <ImageUploadModal
        isModalOpen={showImageUploadModal}
        loading={verifyLoading}
        subTitle={"Image Upload"}
        uploadClickButton={uploadPicToUrl}
        onCancelClick={() => setShowImageUploadModal(false)}
      />
    </Row>
  );
};

export default SrcmDetails;

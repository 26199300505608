import React, { useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Form,
  Input,
  Layout,
  Image,
  Card,
  Typography,
  theme,
  Modal,
  Row,
  Col,
} from "antd";
import {
  FileAddOutlined,
  CloseCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomSider } from "../genericComponents/CustomSider";
import Password from "antd/es/input/Password";
import { CustomHeader } from "../genericComponents/CustomHeader";
import axios from "axios";
import NavBar from "../genericComponents/CustomNav";
import {
  getblockprofile,
  getunblockprofile,
  patchchangePassword,
  getUserDetailsFromLocalStorage,
  getUserDetails,
  setUserDetailsInLocalStorage,
  getProfilePic,
  deleteProfilePic,
  updateProfilePic,
} from "../helperFunctions/endpoints";
import { toast } from "react-toastify";
import ButtonWithIcon from "../genericComponents/ButtonWithIcon";
import ImageUploadModal from "../genericComponents/ImageUploadModal";
import { setProfilePicDetails } from "../store/slices/userSlice";
import { uploadFiles, uploadProfilePicHelper } from "../helperFunctions/utils";
import { getProfilePicAsURL } from "../utils/utils";

const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const AddProfilePic = () => {
  const navigate = useNavigate();
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const deleteImage = async (elementId) => {
    const resp = await deleteProfilePic(elementId);
    if (resp?.code === "CH200") {
      getUserForUI();
      toast.success(resp?.message);
    }
  };

  const setProfilePic = async (picName, elementId) => {
    Modal.confirm({
      title: "Do you want this to be your profile picture?",
      icon: <ExclamationCircleFilled />,

      async onOk() {
        try {
          console.log(
            `${process.env.REACT_APP_API_URL}profile/file/${userDetails?._id}/${elementId}`
          );
          const resp = await uploadProfilePicHelper(
            `${process.env.REACT_APP_API_URL}profile/file/${userDetails?._id}/${elementId}`
          );
          if (resp?.data) {
            const updateResponse = await updateProfilePic(
              resp?.data?.fileName,
              resp?.data?.id,
              true
            );
            if (updateResponse?.code === "CH200") {
              toast.success(updateResponse?.message);
              getUserForUI();
            }
          } else toast.error("Error in setting profile picture");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    getUserForUI();
  }, []);

  const getUserForUI = () => {
    getUserDetails().then((res) => {
      if (res?.code === "CH200") {
        setUserDetailsInLocalStorage(res?.data);
        setUserDetails(res?.data);
      }
    });
  };

  const uploadPicToUrl = async (fileObj) => {
    setLoading(true);
    let userDetails = getUserDetailsFromLocalStorage();
    if (!userDetails?._id) {
      userDetails = await getUserDetails();
    }
    try {
      await uploadFiles("profile/uploadProfilePic", {
        profilePhoto: fileObj,
        primary: false,
      });
    } catch (error) {
      console.log(error);
    }
    getUserForUI();
    setLoading(false);
    setShowImageUploadModal(false);
  };
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content className="content-wrapper">
          <Card
            style={{
              marginTop: "50%",
              fontSize: 18,
              width: 150,
              height: 150,
              marginLeft: "35%",
              marginTop: 50,
            }}
            className="info-card"
          >
            <FileAddOutlined />
            <div onClick={() => setShowImageUploadModal(true)}>
              <b>Add More Photos</b>
            </div>
          </Card>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <Row gutter={[16, 16]}>
              {userDetails?.profilePic?.length > 0 &&
                userDetails?.profilePic?.map((element, index) => {
                  return (
                    !element?.primary && (
                      <Col key={index} xs={8}>
                        <Image
                          preview={false}
                          width={100}
                          height={100}
                          onClick={() =>
                            setProfilePic(element.name, element.id)
                          }
                          style={{ padding: 10 }}
                          src={`${process.env.REACT_APP_API_URL}/profile/file/${userDetails?._id}/${element.id}`}
                        />
                        <CloseCircleOutlined
                          onClick={() => deleteImage(element.id)}
                        />
                      </Col>
                    )
                  );
                })}
            </Row>
          </div>
          <ImageUploadModal
            isModalOpen={showImageUploadModal}
            loading={verifyLoading}
            subTitle={"Image Upload"}
            uploadClickButton={uploadPicToUrl}
            onCancelClick={() => setShowImageUploadModal(false)}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

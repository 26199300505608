import { Button, Modal } from "antd";
import React, { useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "../utils/canvasPreview";
import { toast } from "react-toastify";

const ImageUploadModal = ({
  isModalOpen,
  loading,
  uploadClickButton,
  onCancelClick,
}) => {
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState(undefined);
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = React.useRef(null);
  const previewCanvasRef = React.useRef(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  async function onDownloadCropClick() {
    const image = imgRef.current;
    let previewCanvas = previewCanvasRef.current;
    let completedDefaultCrop = completedCrop;
    if (!image) {
      toast.error("Please select an image");
      return;
    }

    if (!previewCanvas || !completedCrop) {
      const resBlob = await fetch(imgSrc).then((r) => r.blob());
      uploadClickButton(resBlob);
      // const blob = await offscreen.convertToBlob({
      //   type: "image/png",
      // });
      // uploadClickButton(blob);
    } else {
      // This will size relative to the uploaded image
      // size. If you want to size according to what they
      // are looking at on screen, remove scaleX + scaleY
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const offscreen = new OffscreenCanvas(
        completedDefaultCrop.width * scaleX,
        completedDefaultCrop.height * scaleY
      );
      const ctx = offscreen.getContext("2d");
      if (!ctx) {
        throw new Error("No 2d context");
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      );
      // You might want { type: "image/jpeg", quality: <0 to 1> } to
      // reduce image size
      const blob = await offscreen.convertToBlob({
        type: "image/png",
      });
      // var file = new File([blob], "scrmPic.jpeg", {
      //   lastModified: Date.now(),
      //   type: "image/jpeg",
      // });
      uploadClickButton(blob);
    }
  }

  function onCancelClearClick() {
    setImgSrc("");
    onCancelClick();
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop, 1, 0]
  );

  return (
    <div>
      <Modal
        title="Image Upload"
        open={isModalOpen}
        width={"fit-content"}
        className="border-Radius-5px"
        closeIcon={null}
        footer={null}
      >
        <div style={{ marginBottom: "0.5rem" }}>{"Crop Image"}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {imgSrc ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                // minWidth={400}
                minHeight={100}
                aspect={undefined}
              >
                <img
                  src={imgSrc}
                  ref={imgRef}
                  alt="Profile Pic"
                  style={{
                    width: "300px",
                    height: "300px",
                  }}
                  //   onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          ) : (
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          )}
          {!!imgSrc && !!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "cover",
                    objectPosition: "top",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>
            </>
          )}
        </div>
        <Button
          type="primary"
          block
          className="border-Radius-5px block-button-text height-100p marginTop-1rem"
          onClick={onDownloadCropClick}
          loading={loading}
          disabled={imgSrc ? false : true}
        >
          Upload Image
        </Button>
        <Button
          type="default"
          block
          className="border-Radius-5px block-button-text height-100p marginTop-1rem"
          onClick={onCancelClearClick}
        >
          Cancel
        </Button>
      </Modal>
    </div>
  );
};

export default ImageUploadModal;
